const app = {
  init: function () {
    this.initNav();
    this.initVideos();
  },

  initNav: function () {
    const htmlEl = document.querySelector("html");
    const mobileNavBtn = document.querySelector(".main-header__btn-mobile-nav");

    if (mobileNavBtn) {
      mobileNavBtn.addEventListener("click", () => {
        if (htmlEl) {
          htmlEl.classList.toggle("mobile-nav--is-open");
        }
      });
    }
  },

  initVideos: function () {
    fluidvids.init({
      selector: ["iframe", "object"],
      players: ["www.youtube.com", "player.vimeo.com"],
    });

    // look for wp block cover with video
    const heroContainer = document.querySelector(".wp-block-cover");
    const heroVideo = document.querySelector(
      ".wp-block-cover__video-background"
    );

    // hero autoplay check
    if (heroVideo) {
      heroVideo.pause();
      heroVideo.play();

      // check if phone is in power saving mode
      heroVideo.addEventListener("suspend", () => {
        heroVideo.play();

        if (heroVideo.paused) {
          if (heroContainer) {
            heroContainer.classList.add("video--disabled");
          }
        }
      });
    }
  },
};

app.init();
